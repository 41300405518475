import React from 'react';
import './../CSS/footer.css';

class Footer extends React.Component
{

    constructor(props : any)
    {
        super(props);
        this.ImpressumOnClick = this.ImpressumOnClick.bind(this);
    }    
    
    ImpressumOnClick() {
        
    }


    render()
    {
        return (
            <div className="footer">
                    <div className="seperator"></div>
                    <h4 className="color_30" style={{fontSize:"18px"}}>KONTAKT</h4>
                    <h3>Waldberatung Dr. Frank</h3><br></br>
                    <span>
                        <div className="ft-body">
                        <span className="desc">
                            Dipl.-Ing. Dr. Georg Andreas Frank<br></br>
                            Ingenieurbüro für Forst- und Holzwirtschaft<br></br>
                        </span>
                        <span className="desc-italic">
                            Allgemein beeideter und gerichtlich zertifizierter Sachverständiger<br></br>
                        </span>

                        <p className="address">Anastasius Grün Gasse 12/25<br></br>A-1180 Wien</p>
                        <p className="address">Kreuztrattenstraße 158<br></br>A-9500 Villach</p>
                        </div>
                        <div className="contact">
                            <p>
                                <a href="tel:+436644024697">Tel.: +43 (0) 664 402 46 97</a><br></br>
                                <a href="mailto:georg.frank@waldberatung-frank.at">georg.frank@waldberatung-frank.at</a><br></br>
                                <a className="selfLink" href="https://www.waldberatung-frank.at">www.waldberatung-frank.at</a><br></br>
                            </p>

                            <button onClick={this.ImpressumOnClick}>Impressum</button>
                            <p className="copyright">© 2021 Mladek & Patzl</p>
                        </div>
                    </span>
            </div>
        );
    }
}

export default Footer;