import './App.css';
import './templates/CSS/page.css';
import './templates/CSS/page-wide.css';
import './templates/CSS/page-desktop.css';
import './templates/CSS/contact.css';
import './templates/CSS/colors.css';

import React from 'react';
import TemplatePage from './components/TemplatePage';

import Navbar from './components/navbar';

import Footer from './templates/Pages/footer';
import Home from './templates/Pages/home';
import About from './templates/Pages/about'
import Wirtschaft from './templates/Pages/economy';
import Naturschutz from './templates/Pages/conservation';
import Sachverstand from './templates/Pages/expertise';
import Kontrolle from './templates/Pages/control';

const PageNames: string[] = [
  "Home",
  "Waldwirtschaft",
  "Sachverständiger",
  "Naturschutz",
  "Baumkontrolle"
];

const pages: {[key : string]: TemplatePage} = {
                    "Home"              : new Home({}),
                    "About"             : new About({}),
                    "Waldwirtschaft"        : new Wirtschaft({}),
                    "Sachverständiger" : new Sachverstand({}),
                    "Naturschutz"       : new Naturschutz({}),
                    "Baumkontrolle"     : new Kontrolle({})
                };

type AppState = {
  currentPage : string;
}

class App extends React.Component<{},AppState> {
  constructor(props : any)
  {
    super(props);
    this.state = {
      currentPage : "Home",
    };
    this.SetPage = this.SetPage.bind(this);
    pages[this.state.currentPage].OnChangePage = this.SetPage;
    window.addEventListener('scroll', () => {
      let num: string = window.pageYOffset / (document.body.offsetHeight - window.innerHeight) + "";
      document.body.style.setProperty('--scroll', num);
    }, false);
  }

  SetPage(pageName : string)
  {
    pages[pageName].OnChangePage = this.SetPage;
    window.scrollTo({top: 0});
    this.setState({currentPage: pageName});
  }


 render() {
  return (
    <div className="App">
        <div className="spacer">
          <Navbar elements={PageNames} SetPage={this.SetPage}/>
        </div>

        {pages[this.state.currentPage].render()}
        
        <Footer />
    </div>
  );
 }
}

export default App;
