import React from 'react';


export default abstract class TemplatePage<P = {}, S = {}, SS = any> extends React.Component<P, S, SS>
{
    public OnChangePage = (PageName : string) => {};
}


export class TEmptyPage extends TemplatePage
{
    render()
    {
        return this.props.children;
    }
}