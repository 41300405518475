import TemplatePage from "../../components/TemplatePage";
import frank from '../../images/frank_stand.jpg';


export default class Kontrolle extends TemplatePage
{
    render() {
        return (
            <div className="container">
                <div className="column-50">
                    <img src={frank} alt="frank_standing"></img>
                </div>
                <div className="column-50">
                    <h3 className="color_30">BAUMKONTROLLE UND BAUMBEWERTUNG</h3>
                    <p className="quote">Sicherheit geht vor</p>
                    <ul>
                        <li>Baumkontrolle und Baumuntersuchung</li>
                        <li>Baumsicherheit und Gefahrenabschätzung, Baumgutachten</li>
                        <li>Baumwertermittlung nach Schadensfällen</li>
                        <li>Beratung bei der Errichtung von Kletterparks und Waldseilgärten</li>
                        <li>Beratung zur Sicherheit von Freizeitanlagen im Wald</li>
                        <li>Gefahren- und Risikoabschätzung bei der Erhaltung ökologisch wertvoller Baumbestände</li>
                    </ul>
                </div>
            </div>
        );
    }
}