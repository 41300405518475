import React from 'react';
import frank from './../../images/frank.jpg';
import fachverband from './../../images/fachverband.jpg';
import sv_logo from './../../images/sv_logo.jpg';

import TemplatePage from '../../components/TemplatePage';
import './../CSS/about.css';


export default class About extends TemplatePage
{
    OnChangePage = (PageName: string)=> {};

    render() : React.ReactNode {
        return (
            <div className="aboutpage container">
                <div className="title">
                <h3 className="color_30">Meine Leidenschaft,<br></br> der Waldbau.</h3>
                    <span className="quote">
                        <p>
                            &raquo; Bei jeder Beratung prüfe ich mich, ob ich meine Empfehlungen auch in meinem eigenen Wald
                            umsetzen würde. &laquo;
                        </p>
                    </span>
                </div>
                <div className="column-50 title">
                        <img src={frank} alt="titlepic"></img>
                    <div className="career">
                        <h5>Werdegang</h5>
                        <ul>
                            <li>Geboren 1960 in Villach, aufgewachsen in ländlicher Umgebung</li>
                            <li>Höhere Lehranstalt für Forstwirtschaft in Bruck an der Mur 1974-1979</li>
                            <li>Studium Forst- und Holzwirtschaft Universität für Bodenkultur Wien 1979-1986</li>
                            <li>Assistent am Institut für Waldbau bei Prof. Hannes Mayer und Prof. Karl Stoszek 1987-1994</li>
                            <li>Staatsprüfung für den Höheren Forstdienst 1991</li>
                            <li>Forstliche Bundesversuchsanstalt FBVA – Bundesforschungszentrum für Wald BFW seit 1994</li>
                        </ul>
                    </div>
                </div>
                <div className="column-50">
                    <div className="qualifikation">
                        <h5>Qualifikationen</h5>
                        <ul>
                            <li>Leiter der Abteilung Naturwaldreservate, fachliche und wissenschaftliche Betreuung des Österreichischen NWR-Netzes seit 1994</li>
                            <li>Zahlreiche Projekte im Zusammenhang mit Waldbau und Naturschutz im Wald,
                            Internationale Erfahrung in Arbeitsgruppen der Europäischen Kommission,
                            Lektor an der Universität für Bodenkultur Wien (Integrale Landnutzung, Habitatmanagement und Biotoppflege)</li>
                            <li>Engagement im Pro Silva Austria Vorstand seit 1991, Vorsitzender 2006 - 2012</li>
                            <li>Leiter Fachausschuss für Waldbau und Naturschutz des Österreichischen Forstvereins seit 2003</li>
                            <li>Technisches Büro für Forst- und Holzwirtschaft seit 2004</li>
                            <li>Allgemein beeideter und gerichtlich zertifizierter Sachverständiger seit 1996</li>
                        
                        </ul>
                    </div>
                    <div className="zertifikate">
                        <img src={sv_logo} className="zert" alt="zert1"></img>
                        <img src={fachverband} className="zert" alt="zert2"></img>
                    </div>
                </div>
            </div>
        );
    }
}