import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

let lastscroll = 0;
let opacity = 0;

window.addEventListener('scroll', () => {
  let num = window.pageYOffset / (document.body.offsetHeight - window.innerHeight);
  
  if(lastscroll > num)
  {
    opacity = opacity > 0.01? opacity - 1/0.1 * (lastscroll - num) : 0;
  }
  else
  {
    opacity = num === 0 ? num : 1;
    opacity = num < 0.1? num * 10: opacity;
  }
  if (num === 0)
  {
    opacity = 0;
  }
  let str = opacity + "";
  document.body.style.setProperty('--scroll', str);
  lastscroll = num;

}, false);
