import TemplatePage from '../../components/TemplatePage';
import economyPic from '../../images/economy.jpg';

export default class Wirtschaft extends TemplatePage {

    render() {
        return(
            <div className="container">
                <div className="column-50">
                    <img src={economyPic} alt="cut_wood"></img>
                </div>
                <div className="column-50">
                    <h3 className="color_30">NATURNAHE WALDWIRTSCHAFT – BERATUNG UND BETREUUNG</h3>
                    <span className="quote">
                            <p>
                                Der Wald als Naturkapital<br></br>
                            </p>
                        </span>
                    <ul>
                        <li>Nachhaltige Waldbewirtschaftung – Entwicklung von betriebsindividuellen Waldbaukonzepten</li>
                        <li>Waldbauliche Beratung nach den Grundsätzen und Empfehlungen von Pro Silva Austria</li>
                        <li>Standortsbezogene Baumartenempfehlungen und Waldbaumaßnahmen im Klimawandel</li>
                        <li>Betriebliche Naturschutzkonzepte – Integrative waldbauliche Maßnahmen zur Erhaltung und Förderung der Biodiversität</li>
                        <li>Waldwirtschaftspläne und Waldfachpläne</li>
                        <li>Naturkapital und Ökosystemleistungen – Erfassung und Bewertung</li>
                    </ul>
                </div>
            </div>
        );
    };
}