import TemplatePage from '../../components/TemplatePage';
import forest from '../../images/dark_forest.jpg';

export default class Naturschutz extends TemplatePage {
    render() {
        return(
            <div className="container">
                <div className="column-50">
                    <img src={forest} alt="dark_forest"></img>
                </div>
                <div className="column-50">
                    <h3 className="color_30">NATURSCHUTZ UND BIODIVERSITÄT</h3>
                    <p className="quote">Was uns die Natur wert ist</p>
                    <ul>
                        <li>Schutzgebiete – Beratung und Betreuung – Vertragsnaturschutz</li>
                        <li>Besonderer Waldlebensräume – Erfassung und Bewertung</li>
                        <li>Integrativer Naturschutz im Wald – Beratung und Entwicklung</li>
                        <li>Natura 2000 – Beratung – Naturverträglichkeitsprüfung – Entschädigungsfragen</li>
                        <li>Waldbiodiversität – Betriebliche Naturschutzkonzepte</li>
                        <li>Umweltbildung – Wissenstransfer – Naturvermittlung</li>
                    </ul>
                </div>
            </div>
        );
    }
}