import React from 'react';
import frank from '../../images/frank.jpg';
import title from '../../images/wald_title.jpg';
import TemplatePage from '../../components/TemplatePage';
import './../CSS/home.css';

export default class Home extends TemplatePage
{
    constructor(props: any)
    {
        super(props);
        this.AboutOnClick = this.AboutOnClick.bind(this);
    }

    OnChangePage = (PageName : string) => {};

    AboutOnClick(event : React.MouseEvent<HTMLButtonElement>)
    {
        this.OnChangePage("About");
    }

    render() : React.ReactNode {
        return (
            <div className="homepage container font_6">
                <div className= "TitleContainer head">
                    <img src={title} alt="titlepic"></img>;
                    <h3>MEINE LEIDENSCHAFT IST DER WALDBAU</h3>
                </div>

                <div className="column-50">
                    <span>
                        <h4>DIPL.-ING. DR.<br></br>
                        GEORG ANDREAS FRANK</h4>
                    </span>

                    <span className="text-desc">
                        <p>Ingenieurbüro für Forst- und Holzwirtschaft</p>
                        <p>Allgemein beeideter und gerichtlich zertifizierter Sachverständiger</p>
                    </span>

                    <span className="quote">
                        <p>
                            &raquo;Bei jeder Beratung prüfe ich mich, ob ich meine Empfehlungen auch in meinem eigenen Wald
                            umsetzen würde.&laquo;<br></br>
                        </p>
                    </span>

                    <div className="contact">
                        <div className="seperator"></div>
                        <h4 className="color_30" style={{fontSize:"17px"}}>KONTAKT</h4>
                        <p>Anastasius Grün Gasse 12/25<br></br>A-1180 Wien</p>
                        <p>Kreuztrattenstraße 158<br></br>A-9500 Villach</p>
                        <p>
                            <a href="tel:+436644024697">Tel.: +43 (0) 664 402 46 97</a><br></br>
                            <a href="mailto:georg.frank@waldberatung-frank.at">georg.frank@waldberatung-frank.at</a><br></br>
                            <a className="selfLink" href="https://www.waldberatung-frank.at">www.waldberatung-frank.at</a><br></br>
                        </p>
                        <div className="seperator"></div>
                        <h4 className="font_4 color_30">ÜBER MICH</h4>
                        <button style={{marginTop : "15px", marginBottom: "20px"}} onClick={this.AboutOnClick}>Mehr erfahren</button>
                    </div>
                </div>
                <div className="column-50">
                    <img src={frank} alt="frank-bild missing"></img>
                </div>
            </div>
        );
    }
}