import React from "react"
import "./navbar.css"
import logo from './../images/logo.gif';

type NavbarProperties = 
{
    elements : string[];
    SetPage: (page : string) => void;
}

type NavbarState = {
    activeTab : string;
    menuOpen: boolean;
}

export default class Navbar extends React.Component<NavbarProperties, NavbarState>
{
    menuButtonClass: string = "menu";

    constructor(props: NavbarProperties) {
        super(props);
        this.state = {
            activeTab: "Home",
            menuOpen: false
        };
        this.onClick = this.onClick.bind(this);
        this.openMenu = this.openMenu.bind(this);
    }

    get NavTabs()
    {
        var tabs : React.ReactNodeArray = [];
        var num: number = 0;
        for(var k of this.props.elements)
        {
            var classname = k === this.state.activeTab? "TabActive" : "";
            var className2 = this.state.menuOpen ? " open" : " close";
            tabs.push(<button key={"button"+num} className={classname + className2} onClick={this.onClick}>{k}</button>)
            num++;
        }
        return tabs;
    }


    onClick(event: React.MouseEvent<HTMLButtonElement>)  {
        this.menuButtonClass = "menu close";
        var text = event.currentTarget.innerHTML;
        this.props.SetPage(text);
        window.scrollTo({top: 0});
        this.setState({activeTab : text, menuOpen: false});
    }

    openMenu(event: React.MouseEvent<HTMLInputElement>) {
        if (this.state.menuOpen)
            this.menuButtonClass = "menu close";
        else
            this.menuButtonClass = "menu open";
        this.setState((s) => {return { menuOpen : !s.menuOpen};});
    }

    render() {
        return (
            <div className="Navbar">
                <div className="logo">
                    <a href="/"><img src={logo} alt="logo"></img></a>
                </div>
                                    
                <div className="menuButtons">
                    {this.NavTabs}
                </div>

                <div className={this.menuButtonClass} onClick={this.openMenu}>
                    <label className="hamburger" id="hamburger">
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                    </label>
                </div>
            </div>
        )
    }
}