import TemplatePage from "../../components/TemplatePage";
import frank from '../../images/working_frank.jpg';
import sv_logo from './../../images/sv_logo.jpg';

export default class Sachverstand extends TemplatePage 
{
    render() {
        return (
            <div className="container">
                <div className="column-50">
                    <img src={frank} alt="working_frank"></img>
                </div>
                <div className="column-50">
                    <h3 className="color_30">ALLGEMEIN BEEIDETER UND GERICHTLICH ZERTIFIZIERTER SACHVERSTÄNDIGER</h3>
                    <div style={{width:"100%", textAlign:"center", margin: "5%"}}>
                        <a href="http://www.sdgliste.justiz.gv.at/">http://www.sdgliste.justiz.gv.at/</a>
                    </div>
                    <p className="quote">Unabhängig, objektiv und unparteilich</p>
                    <ul>
                        <li>Gutachten im Gerichts- und Verwaltungsverfahren</li>
                        <li>Verkehrswertgutachten forstwirtschaftlicher Liegenschaften</li>
                        <li>Bewertung von Wildschäden, Wildschadensmonitoring</li>
                        <li>Waldbewertung zur Entschädigung in Natura 2000 Gebieten</li>
                        <li>Baumgutachten insbesondere zur Verkehrssicherheit</li>
                        <li>Kontrolle und Begutachtung des Baumbestandes in Hochseilgärten und touristischen Einrichtungen im Wald</li>
                    </ul>
                    <div className="zertifikate">
                        <a href="http://www.sdgliste.justiz.gv.at/"><img src={sv_logo} className="zert" alt="zert1"></img></a>
                    </div>
                </div>
            </div>
        );
    }
}